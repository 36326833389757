<template>
<div id="GoldenRatio__wrapper">

  <div id="GoldenRatio__container">
    <div id="GoldenRatio__top-button" @click="moveSpiral">Rotate</div>

    <div id="GoldenRatio__top-description">
      <span class="golden-ratio-title">The golden ratio</span>
      <span class="golden-ratio-svg"></span>
    </div>

    <div id="GoldenRatio__bottom">
      <span>Example of a spiral using the golden ratio as inspiration, made with css & js :-)</span>
      <span class="reset-link" @click="resetSpiral">Reset</span>
    </div>

    <div id="GoldenRatio__numbers">
      <div>89</div>
      <div>55</div>
      <div>34</div>
      <div>21</div>
      <div>13</div>
      <div>8</div>
      <div>5</div>
      <div>3</div>
      <div>2</div>
      <div>1</div>
      <div>0</div>
    </div>

    <div id="GoldenRatio"></div>

  </div>
</div>
</template>

<script>
import { onMounted } from 'vue';
import { quotes } from '../components/golden_ratio/golden-ratio-quotes';

export default {
  name: 'GoldenRatio',
  setup () {
    let squares = {
      spare_610: { top: 0, left: 0, size: 610 },
      spare_377: { top: 0, right: 0, size: 377 },
      spare_233: { bottom: 0, right: 0, size: 233 },
      spare_144: { bottom: 0, left: 610, size: 144 },
      spare_89: { top: 377, left: 610, size: 89 },
      spare_55: { top: 377, right: 233, size: 55 },
      spare_34: { bottom: 144, right: 233, size: 34 },
      spare_21: { bottom: 144, left: 699, size: 21 },
      // spare_13: { top: 432, left: 699, size: 13, },
      // spare_8: { top: 432, right: 267, size: 8, },
      // spare_5: { bottom: 165, right: 267, size: 5, },
      // spare_3: { bottom: 165, left: 712, size: 3, },
    };
    let number_size = [89, 55, 33.99, 21.01, 12.98, 8.02, 4.96, 3.06, 1.89, 1.17];
    let heading_size = [33.99, 21.01, 12.98, 8.02, 4.96, 3.06, 1.89, 1.17, 0.72, 0.45];
    let text_size = [21.01, 12.98, 8.02, 4.96, 3.06, 1.89, 1.17, 0.72, 0.45, 0.28];
    let currentNumber = 1;


    function drawSpiral () {
      let GoldenRatio = document.querySelector('#GoldenRatio');

      let theContainer = document.getElementById('GoldenRatio__content');
      if (theContainer) theContainer.remove();

      let container = document.createElement('div');
      container.id = 'GoldenRatio__content';

      let index = 0;
      for (const key in squares) {
        let numberToDisplay = currentNumber + index;
        if (numberToDisplay <= 32) {

          let hslValue = (numberToDisplay + 10) * 20;
          let square = document.createElement('div');
          if (squares[key].top !== undefined) square.style.top = squares[key].top + 'px';
          if (squares[key].bottom !== undefined) square.style.bottom = squares[key].bottom + 'px';
          if (squares[key].left !== undefined) square.style.left = squares[key].left + 'px';
          if (squares[key].right !== undefined) square.style.right = squares[key].right + 'px';
          square.style.height = squares[key].size + 'px';
          square.style.width = squares[key].size + 'px';
          square.style.background = `hsl(${hslValue}, 40%, 50%)`;
          square.setAttribute('data-square-number', numberToDisplay + '');
          if (index === 7) square.id = 'last_square';
          square.className = 'aSquare';

          if (index === 0) GoldenRatio.style.background = `hsl(${hslValue}, 40%, 50%)`;
          if (index === 1 || index === 5 || index === 9) square.style.transform = 'rotate(90deg)';
          if (index === 2 || index === 6 || index === 10) square.style.transform = 'rotate(180deg)';
          if (index === 3 || index === 7 || index === 11) square.style.transform = 'rotate(270deg)';

          // if (numberToDisplay === 3) {
          //   square.style.backgroundImage = 'url(/unsplash/_golden-ring.jpeg)';
          //   square.style.backgroundPosition = '50%';
          //   square.style.backgroundSize = '100%';
          // }

          let heading = document.createElement('div');
          heading.className = 'square__heading';
          heading.innerText = quotes['quote_' + numberToDisplay].from;
          heading.style.fontSize = heading_size[index] + 'px';

          let number = document.createElement('div');
          number.className = 'square__number';
          number.innerText = numberToDisplay + '';
          number.style.fontSize = number_size[index] + 'px';

          let text = document.createElement('div');
          text.className = 'square__text';
          text.innerText = quotes['quote_' + numberToDisplay].text;
          text.style.fontSize = text_size[index] + 'px';

          let square__contents = document.createElement('div');
          square__contents.className = 'square__contents';
          square__contents.append(heading, number, text);

          square.append(square__contents);

          container.append(square);
        }

        index++;
      }

      currentNumber++;
      GoldenRatio.append(container);
    }


    function moveSpiral () {
      let rotateSpiral = document.getElementById('GoldenRatio__top-button');
      rotateSpiral && (rotateSpiral.style.animation = 'none');

      let spiral = document.getElementById('GoldenRatio__content');
      let spiralFirst = spiral && spiral.firstElementChild;
      let firstNumber = spiralFirst && spiralFirst.getAttribute('data-square-number');

      if (firstNumber && (parseInt(firstNumber) <= 31)) {
        spiral.ontransitionend = () => drawSpiral();
        spiral.style.transform = `rotate(-90deg) scale(1.618)`;
      }
    }


    function resetSpiral () {
      currentNumber = 1;
      drawSpiral();
    }


    onMounted(() => {
      drawSpiral();
    });


    return {
      moveSpiral,
      resetSpiral,
    };
  },
};
</script>

<style lang="stylus">
#GoldenRatio__wrapper
  min-height 100vh
  width 100vw
  position relative
  display flex
  align-items center
  justify-content center
  #GoldenRatio__container
    box-shadow 0 10px 30px 0 rgba(0, 0, 0, 0.32)
    background-image linear-gradient(#fff, #f5f5fa)
    border-radius 20px
    position relative
    width 1108px
    height 690px
    #GoldenRatio__top-button
      position absolute
      z-index 200
      top 15px
      left 15px
      width 65px
      height 65px
      border-radius 50%
      background-color darkgoldenrod
      color white
      font italic 700 16px AvertaStd
      letter-spacing 1px
      display flex
      align-items center
      justify-content center
      cursor pointer
      box-shadow: 0 0 0 2px white;
      animation borderAnimation 700ms infinite
    #GoldenRatio__top-description
      position absolute
      top 0
      left 110px
      bottom auto
      right 0
      height 50px
      display flex
      align-items center
      justify-content flex-start
      font 400 14px Averta
      color black
      .golden-ratio-title
        text-transform uppercase
        letter-spacing 1px
        font 400 20px AvertaStd
        margin-right 50px
      .golden-ratio-svg
        height 50px
        width 160px
        background-image url("../../public/golden-ratio/goldenRatio.svg")
        background-repeat no-repeat
        background-position center
        background-size 150px
    #GoldenRatio__bottom
      position absolute
      bottom 0
      left 40px
      right 0
      height 30px
      font 400 14px Averta
      color #7f7f7f
      display flex
      align-items center
      justify-content space-evenly
      .reset-link
        cursor pointer
        font-weight bold
        color cornflowerblue
        text-decoration underline
    #GoldenRatio__numbers
      border-top 1px dashed #bcc3e2
      position absolute
      top 105px
      left 37px
      width 25px
      > div
        border-bottom 1px dashed #bcc3e2
        font 500 18px Averta
        margin 0 auto
        color #bcc3e2
        padding 12px 0
    #GoldenRatio
      position absolute
      top 50px
      left 100px
      width 987px
      height 610px
      overflow hidden
      border-radius 20px
      background-color olive
      transition background-color 1s linear
      #GoldenRatio__content
        transform rotate(0deg)
        transform-origin 714.5px 441.5px
        transition transform 1s linear
        will-change transform
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        .aSquare
          position absolute
          box-sizing border-box
          font-family Averta
          font-weight 400
          &#last_square
            animation showLast .5s linear
          .square__contents
            position absolute
            overflow hidden
            z-index 200
            top 0
            right 0
            bottom 0
            left 0
            .square__number
              font-family AvertaStd
              position absolute
              left 5%
              bottom 5%
            .square__heading
              position absolute
              text-transform uppercase
              top 30%
              left 20%
              right 20%
              height 20%
            .square__text
              position absolute
              top 50%
              left 15%
              right 15%
              color #cfe9e3

@keyframes showLast
  0%
    opacity 0
  100%
    opacity 1

@keyframes borderAnimation
  0%
    box-shadow 0 0 0 0 red
  50%
    box-shadow 0 0 0 3px red
  100%
    box-shadow 0 0 0 0 red
</style>