export let quotes = {
  quote_1: {
    from: 'Plato',
    text: 'Wise men talk because they have something to say; fools, because they have to say something.'
  },
  quote_2: {
    from: 'Albert Einstein',
    text: 'The true sign of intelligence is not knowledge but imagination.'
  },
  quote_3: {
    from: 'Oscar Wilde',
    text: 'Nowadays people know the price of everything and the value of nothing.'
  },
  quote_4: {
    from: 'Ernest Hemingway',
    text: 'Before you act, listen. Before you react, think. Before you spend, earn. Before you criticize, wait. Before you pray, forgive. Before you quit, try.'
  },
  quote_5: {
    from: 'Eleanor Roosevelt',
    text: 'Great minds discuss ideas.\n Average minds discuss events.\n Small minds discuss people.'
  },
  quote_6: {
    from: 'Aristotle',
    text: 'It is the mark of an educated mind to be able to entertain a thought without accepting it.'
  },
  quote_7: {
    from: 'Plato',
    text: 'Those who are able to see beyond the shadows and lies of their culture will never be understood, let alone believed, by the masses.'
  },
  quote_8: {
    from: 'Nelson Mandela',
    text: 'The greatest glory in living lies not in never falling, but in rising every time we fall.',
  },
  quote_9: {
    from: 'Steve Jobs',
    text: 'Your time is limited, so don\'t waste it living someone else\'s life. Don\'t be trapped by dogma – which is living with the results of other people\'s thinking.',
  },
  quote_10: {
    from: 'Robert Louis Stevenson',
    text: 'Don\'t judge each day by the harvest you reap but by the seeds that you plant.',
  },
  quote_11: {
    from: 'Ralph Waldo Emerson',
    text: 'Do not go where the path may lead, go instead where there is no path and leave a trail.',
  },
  quote_12: {
    from: 'Henry Ford',
    text: 'When everything seems to be going against you, remember that the airplane takes off against the wind, not with it.',
  },
  quote_13: {
    from: 'David Brinkley',
    text: 'A successful man is one who can lay a firm foundation with the bricks others have thrown at him.',
  },
  quote_14: {
    from: 'Joshua J. Marine',
    text: 'Challenges are what make life interesting and overcoming them is what makes life meaningful.',
  },
  quote_15: {
    from: 'Francis Chan',
    text: 'Our greatest fear should not be of failure… but of succeeding at things in life that don’t really matter.',
  },
  quote_16: {
    from: 'Leonardo Da Vinci',
    text: 'It had long since come to my attention that people of accomplishment rarely sat back and let things happen to them. They went out and happened to things.',
  },
  quote_17: {
    from: 'Aristotle',
    text: 'We are what we repeatedly do; excellence, then, is not an act but a habit.',
  },
  quote_18: {
    from: 'Albert Einstein',
    text: 'The person who reads too much and uses his brain too little will fall into lazy habits of thinking.',
  },
  quote_19: {
    from: 'Lao Tzu',
    text: 'The journey of a thousand miles begins... with one step.',
  },
  quote_20: {
    from: 'Gandhi',
    text: 'You must be the change you wish to see in the world.',
  },
  quote_21: {
    from: 'Josiah Gilbert Holland',
    text: 'There is no royal road to anything. One thing at a time, all things in succession. That which grows fast, withers as rapidly. That which grows slowly, endures.',
  },
  quote_22: {
    from: 'Oliver Wendell Holmes',
    text: 'The great thing in this world is not so much where you stand, as in what direction you are moving.',
  },
  quote_23: {
    from: 'George Bernard Shaw',
    text: 'Some men see things as they are and say why – I dream things that never were and say why not.',
  },
  quote_24: {
    from: 'Larry Winget',
    text: 'Nobody ever wrote down a plan to be broke, fat, lazy, or stupid. Those things are what happen when you don’t have a plan.',
  },
  quote_25: {
    from: 'Stephen Covey',
    text: 'I am not a product of my circumstances. I am a product of my decisions.',
  },
  quote_26: {
    from: 'Elon Musk',
    text: 'When something is important enough, you do it even if the odds are not in your favor.',
  },
  quote_27: {
    from: 'Confucius',
    text: 'It does not matter how slowly you go so long as you do not stop. ',
  },
  quote_28: {
    from: 'Friedrich Nietzsche',
    text: 'That which does not kill us makes us stronger.',
  },
  quote_29: {
    from: 'Muhammad Ali',
    text: 'Often it isn’t the mountains ahead that wear you out, it’s the little pebble in your shoe. ',
  },
  quote_30: {
    from: 'Lionel Messi',
    text: 'A lion doesn’t concern himself with the opinions of the sheep.',
  },
  quote_31: {
    from: 'Malcolm X',
    text: 'I’m for truth, no matter who tells it. I’m for justice, no matter who it’s for or against.',
  },
  quote_32: {
    from: 'Julius Caesar',
    text: 'It is easier to find men who will volunteer to die, than to find those who are willing to endure pain with patience. ',
  },
};